export function formatDate(date, format = 'YYYY-DD-MM') {
    const year = date.getFullYear()
    const month = date.getMonth() + 1
    const day = date.getDate()
    const hours = date.getHours()
    const minutes = date.getMinutes()
    const seconds = date.getSeconds()

    // Replace format placeholders with corresponding values
    const formattedDate = format
        .replace('YYYY', year)
        .replace('MM', String(month).padStart(2, '0'))
        .replace('DD', String(day).padStart(2, '0'))
        .replace('HH', String(hours).padStart(2, '0'))
        .replace('mm', String(minutes).padStart(2, '0'))
        .replace('ss', String(seconds).padStart(2, '0'))

    return formattedDate
}

export function formatDateToYYYYMMDDHHMMSS(date) {
    // Extract date components
    const year = date.getFullYear()
    const month = String(date.getMonth() + 1).padStart(2, '0')
    const day = String(date.getDate()).padStart(2, '0')
    const hours = String(date.getHours()).padStart(2, '0')
    const minutes = String(date.getMinutes()).padStart(2, '0')
    const seconds = String(date.getSeconds()).padStart(2, '0')

    // Combine the components into the desired format
    const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`

    return formattedDate
}

export function compareTwoDates(dateString1, dateString2) {
    const date1 = Date.parse(dateString1)
    const date2 = Date.parse(dateString2)

    if (isNaN(date1) || isNaN(date2)) {
        throw new Error('Invalid date format')
    }

    if (date1 > date2) {
        return 1 // date1 is greater than date2
    } else if (date1 < date2) {
        return -1 // date1 is less than date2
    } else {
        return 0 // Both dates are equal
    }
}
