import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import BaseService from 'services/BaseService'
import { popNotification } from 'utils/popNotification'
import { notificationConfig } from './constants'
import { LIMIT } from 'constants/app.constant'

const { creation, deletion } = notificationConfig

// Redux Toolkit slice
const citiesSlice = createSlice({
    name: 'cities',
    initialState: {
        data: [],
        cityById: {},
        isLoading: false,
        error: null,
        created: null,
        isDeleted: null,
        currentPage: 1,
    },
    reducers: {
        resetDataCityById: (state) => {
            state.cityById = {}
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchCities.pending, (state) => {
                state.isLoading = true
                state.error = null
            })
            .addCase(fetchCities.fulfilled, (state, action) => {
                const currentPage = action?.meta?.arg?.page || 1
                state.currentPage = currentPage
                state.isLoading = false
                state.data = action.payload
            })
            .addCase(fetchCities.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
            })
            .addCase(fetchCityById.pending, (state) => {
                state.isLoading = true
                state.error = null
            })
            .addCase(fetchCityById.fulfilled, (state, action) => {
                state.isLoading = false
                state.cityById = action.payload
            })
            .addCase(fetchCityById.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
            })
            .addCase(createCity.pending, (state) => {
                state.isLoading = true
                state.error = null
            })
            .addCase(createCity.fulfilled, (state, action) => {
                state.isLoading = false
                state.created = true
            })
            .addCase(createCity.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
                state.created = false
            })
            .addCase(deleteDistributor.pending, (state) => {
                state.isLoading = true
                state.isDeleted = null
            })
            .addCase(deleteDistributor.fulfilled, (state) => {
                state.isLoading = false
                state.isDeleted = true
            })
            .addCase(deleteDistributor.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
                state.isDeleted = false
            })
    },
})

// Async thunk action to fetch data from the API
export const fetchCities = createAsyncThunk('cities/fetch', async (payload) => {
    const page = payload?.page || 1
    const limit = payload?.limit || LIMIT
    try {
        const response = await BaseService.get(
            `/villes?page=${page}&limit=${limit}`
        )
        return response.data
    } catch (error) {
        throw new Error('Failed to fetch cities')
    }
})
export const fetchCityById = createAsyncThunk(
    'cities/fetchCityById',
    async (payload) => {
        const { id } = payload
        try {
            const response = await BaseService.get(`/villes/${id}`)
            return response.data
        } catch (error) {
            throw new Error('Failed to fetch city by id')
        }
    }
)

export const createCity = createAsyncThunk('cities/post', async (payload) => {
    try {
        const response = await BaseService.post('/distributeurs', payload)
    } catch (err) {
        console.log({ err })
        const status = err.response.status
        if (status === 409) {
            popNotification(creation?.conflict)
            return
        }
        popNotification(creation?.error)
    }
})

export const deleteDistributor = createAsyncThunk(
    'cities/delete',
    async (payload) => {
        const { id } = payload
        if (!id) return
        try {
            const response = await BaseService.delete(`/villes/${id}`)
            if (response.status === 200 || response.status === 201) {
                popNotification(deletion?.success)
                return true
            }
        } catch (err) {
            console.log({ err })
            popNotification(deletion?.error)
            return
        }
    }
)

export const selectData = (state) => state.cities.data
export const selectDataById = (state) => state.cities.cityById
export const selectIsLoading = (state) => state.cities.isLoading
export const selectError = (state) => state.cities.error
export const selectIsDeleted = (state) => state.cities.isDeleted
export const selectCurrentPage = (state) => state.cities.currentPage

export const { resetDataCityById } = citiesSlice.actions

export default citiesSlice.reducer
