import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import BaseService from 'services/BaseService'
import { popNotification } from 'utils/popNotification'
import { notificationConfig } from './constants'
import { LIMIT } from 'constants/app.constant'
import { formatDateToYYYYMMDDHHMMSS } from 'utils/dateFormattor'

const { creation, edit, deletion } = notificationConfig

// Redux Toolkit slice
const missionsSlice = createSlice({
    name: 'missions',
    initialState: {
        data: [],
        isLoading: false,
        error: null,
        created: null,
        isDeleted: null,
        currentPage: 1,
        quartierSupervisors: [],
        report: {},
    },
    reducers: {
        setQuartierSupervisorsData: (state, action) => {
            state.quartierSupervisors = action.payload
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchMissions.pending, (state) => {
                state.isLoading = true
                state.error = null
            })
            .addCase(fetchMissions.fulfilled, (state, action) => {
                const currentPage = action?.meta?.arg?.page || 1
                state.currentPage = currentPage
                state.isLoading = false
                state.data = action.payload
            })
            .addCase(fetchMissions.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
            })
            .addCase(createMission.pending, (state) => {
                state.isLoading = true
                state.error = null
            })
            .addCase(createMission.fulfilled, (state, action) => {
                state.isLoading = false
                state.created = true
            })
            .addCase(createMission.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
                state.created = false
            })
            .addCase(createQuartierDistributor.pending, (state) => {
                state.isLoading = true
            })
            .addCase(createQuartierDistributor.fulfilled, (state, action) => {
                state.isLoading = false
            })
            .addCase(createQuartierDistributor.rejected, (state, action) => {
                state.isLoading = false
            })
            .addCase(createDistributedQuantity.pending, (state) => {
                state.isLoading = true
            })
            .addCase(createDistributedQuantity.fulfilled, (state, action) => {
                state.isLoading = false
            })
            .addCase(createDistributedQuantity.rejected, (state, action) => {
                state.isLoading = false
            })
            .addCase(deleteMission.pending, (state) => {
                state.isLoading = true
                state.isDeleted = null
            })
            .addCase(deleteMission.fulfilled, (state) => {
                state.isLoading = false
                state.isDeleted = true
            })
            .addCase(deleteMission.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
                state.isDeleted = false
            })
    },
})

// Async thunk action to fetch data from the API
export const fetchMissions = createAsyncThunk(
    'missions/fetch',
    async (payload) => {
        const page = payload?.page || 1
        const limit = payload?.limit || LIMIT
        try {
            const response = await BaseService.get(
                `/missions?page=${page}&limit=${limit}`
            )
            return response.data
        } catch (error) {
            throw new Error('Failed to fetch missions')
        }
    }
)

export const createMission = createAsyncThunk(
    'missions/post',
    async (payload) => {
        const { data, navigate } = payload
        const [start, end] = data?.dateRange

        const startdate = formatDateToYYYYMMDDHHMMSS(start)
        const enddate = formatDateToYYYYMMDDHHMMSS(end)
        const missionData = { ...data, startdate, enddate }
        try {
            const response = await BaseService.post('/missions', missionData)
            if (response.status === 200) {
                popNotification(creation?.success)
                navigate(`/missions/edit/${response.data.id}`)
                return true
            }
        } catch (err) {
            console.log({ err })
            popNotification(creation?.error)
        }
    }
)

export const downloadMissionReport = createAsyncThunk(
    'missions/download',
    async (missionId, { rejectWithValue }) => {
        try {
            const response = await BaseService.get('/dashboards/download/report/' + missionId, {
                responseType: 'blob', // Important to handle binary data
            });
            if (response.status === 200) {
                const contentDisposition = response.headers['content-disposition'];
                let fileName = 'report.pdf'; // Default file name
                if (contentDisposition) {
                    const fileNameMatch = contentDisposition.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/);
                    if (fileNameMatch && fileNameMatch.length > 1) {
                        fileName = fileNameMatch[1].replace(/['"]/g, ''); // Remove any quotes from filename
                    }
                }
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', fileName);
                document.body.appendChild(link);
                link.click();

                window.URL.revokeObjectURL(url);
                link.remove();
                return response.data;
            } else {
                return rejectWithValue('File download failed');
            }
        } catch (err) {
            console.log({ err });
            return rejectWithValue(err.response?.data || 'Unknown error occurred');
        }
    }
);


export const createQuartierDistributor = createAsyncThunk(
    'quartierDistributors/post',
    async (payload) => {
        try {
            const response = await BaseService.post(
                '/quartier_distributeurs/',
                payload
            )
            if (response.status === 200) {
                popNotification(creation?.success)
                return true
            }
        } catch (err) {
            console.log({ err })
            popNotification(creation?.error)
        }
    }
)
export const createDistributedQuantity = createAsyncThunk(
    'distributed_depliants/post',
    async (payload) => {
        try {
            const response = await BaseService.post(
                '/distributed_depliants/',
                payload
            )
            if (response.status === 200) {
                popNotification(creation?.success)
                return true
            }
        } catch (err) {
            console.log({ err })
            popNotification(creation?.error)
        }
    }
)

export const editMission = createAsyncThunk(
    'missions/edit',
    async (payload) => {
        const { data, patch = '', setUrlVersion } = payload
        try {
            let response
            if (patch) {
                response = await BaseService.patch(
                    `/missions/${data?.id}`,
                    data
                )
                setUrlVersion((prev) => prev + 1)
                return
            } else {
                response = await BaseService.put(`/missions/${data?.id}`, data)
            }

            if (response.status === 200 || response.status === 201) {
                popNotification(edit?.success)
            }
        } catch (err) {
            console.log({ err })
            const status = err?.response?.status
            if (status === 409) {
                popNotification(creation?.conflict)
                return
            }
            popNotification(edit?.error)
        }
    }
)

export const deleteMission = createAsyncThunk(
    'missions/delete',
    async (payload) => {
        const { id, closeDialog } = payload
        if (!id) return
        try {
            const response = await BaseService.delete(`/missions/${id}`)
            if (response.status === 200 || response.status === 201) {
                popNotification(deletion?.success)
                closeDialog()
                return true
            }
        } catch (err) {
            console.log({ err })
            popNotification(deletion?.error)
            return
        }
    }
)

export const selectData = (state) => state.missions.data
export const selectIsLoading = (state) => state.missions.isLoading
export const selectError = (state) => state.missions.error
export const selectIsDeleted = (state) => state.missions.isDeleted
export const selectCurrentPage = (state) => state.missions.currentPage
export const selectQuartierSupervisors = (state) =>
    state.missions.quartierSupervisors

export default missionsSlice.reducer

export const { setQuartierSupervisorsData } = missionsSlice.actions
