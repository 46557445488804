import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import BaseService from 'services/BaseService'
import { popNotification } from 'utils/popNotification'
import { notificationConfig } from './constants'
import { LIMIT } from 'constants/app.constant'

const { creation, edit, deletion } = notificationConfig

// Redux Toolkit slice
const distributorsSlice = createSlice({
    name: 'distributors',
    initialState: {
        data: [],
        isLoading: false,
        error: null,
        created: null,
        isDeleted: null,
        currentPage: 1,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchDistributors.pending, (state) => {
                state.isLoading = true
                state.error = null
            })
            .addCase(fetchDistributors.fulfilled, (state, action) => {
                const currentPage = action?.meta?.arg?.page || 1
                state.currentPage = currentPage
                state.isLoading = false
                state.data = action.payload
            })
            .addCase(fetchDistributors.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
            })
            .addCase(createDistributor.pending, (state) => {
                state.isLoading = true
                state.error = null
            })
            .addCase(createDistributor.fulfilled, (state, action) => {
                state.isLoading = false
                state.created = true
            })
            .addCase(createDistributor.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
                state.created = false
            })
            .addCase(deleteDistributor.pending, (state) => {
                state.isLoading = true
                state.isDeleted = null
            })
            .addCase(deleteDistributor.fulfilled, (state) => {
                state.isLoading = false
                state.isDeleted = true
            })
            .addCase(deleteDistributor.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
                state.isDeleted = false
            })
    },
})

// Async thunk action to fetch data from the API
export const fetchDistributors = createAsyncThunk(
    'distributors/fetch',
    async (payload) => {
        const page = payload?.page || 1
        const limit = payload?.limit || LIMIT
        try {
            const response = await BaseService.get(
                `/distributeurs?page=${page}&limit=${limit}`
            )
            return response.data
        } catch (error) {
            throw new Error('Failed to fetch distributors')
        }
    }
)

export const createDistributor = createAsyncThunk(
    'distributors/post',
    async (payload) => {
        const { data, formik, navigate } = payload
        const { resetForm, setSubmitting } = formik
        try {
            const response = await BaseService.post('/distributeurs', data)
            if (response.status === 200) {
                resetForm()
                setSubmitting(false)
                popNotification(creation?.success)
                // navigate('/distributors')
                return true
            }
        } catch (err) {
            console.log({ err })
            const status = err.response.status
            setSubmitting(false)
            if (status === 409) {
                popNotification(creation?.conflict)
                return
            }
            popNotification(creation?.error)
        }
    }
)

export const editDistributor = createAsyncThunk(
    'distributors/edit',
    async (payload) => {
        const { data, formik, navigate } = payload
        const { resetForm, setSubmitting } = formik
        try {
            const response = await BaseService.put(
                `/distributeurs/${data?.id}`,
                data
            )
            if (response.status === 200) {
                resetForm()
                setSubmitting(false)
                popNotification(edit?.success)
                // navigate('/distributors')
                return true
            }
        } catch (err) {
            console.log({ err })
            const status = err.response.status
            setSubmitting(false)
            if (status === 409) {
                popNotification(creation?.conflict)
                return
            }
            popNotification(edit?.error)
        }
    }
)

export const deleteDistributor = createAsyncThunk(
    'distributor/delete',
    async (payload) => {
        const { id, closeDialog } = payload
        if (!id) return
        try {
            const response = await BaseService.delete(`/distributeurs/${id}`)
            if (response.status === 200 || response.status === 201) {
                popNotification(deletion?.success)
                closeDialog()
                return true
            }
        } catch (err) {
            console.log({ err })
            popNotification(deletion?.error)
            return
        }
    }
)

export const selectData = (state) => state.distributors.data
export const selectIsLoading = (state) => state.distributors.isLoading
export const selectError = (state) => state.distributors.error
export const selectIsDeleted = (state) => state.distributors.isDeleted
export const selectCurrentPage = (state) => state.distributors.currentPage

export default distributorsSlice.reducer
