const appConfig = {
    apiPrefix: '/api',
    authenticatedEntryPath: '/home',
    unAuthenticatedEntryPath: '/sign-in',
    tourPath: '/',
    locale: 'en',
    enableMock: false,
    baseUrl: 'https://api.trackmyflyer.holisticmedia.uk.pulsioncloud.com/api',
    // baseUrl: 'http://localhost:5000/api',
}

export default appConfig
