import React, { useEffect, useState } from 'react'
import { Input } from 'components/ui'
import { HiOutlineEyeOff, HiOutlineEye } from 'react-icons/hi'
import decrypt from 'utils/decrypt'

const PasswordInput = (props) => {
    const {
        onVisibleChange,
        customHandler = false,
        form,
        field,
        ...rest
    } = props
    const [pwInputType, setPwInputType] = useState('password')
    const [decrypted, setDecrypted] = useState(false)

    const onPasswordVisibleClick = (e) => {
        e.preventDefault()
        const nextValue = pwInputType === 'password' ? 'text' : 'password'
        setPwInputType(nextValue)
        if (customHandler && !decrypted) {
            const password = decrypt(field.value)
            form.setFieldValue(field.name, password)
            setDecrypted(true)
        }
        onVisibleChange?.(nextValue === 'text')
    }

    return (
        <Input
            {...rest}
            form={form}
            field={field}
            type={pwInputType}
            suffix={
                <span
                    className="cursor-pointer text-xl"
                    onClick={(e) => onPasswordVisibleClick(e)}
                >
                    {pwInputType === 'password' ? (
                        <HiOutlineEyeOff />
                    ) : (
                        <HiOutlineEye />
                    )}
                </span>
            }
        />
    )
}

export default PasswordInput
