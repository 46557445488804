export const APP_NAME = 'Elstar'
export const PERSIST_STORE_NAME = 'admin'
export const REDIRECT_URL_KEY = 'redirectUrl'
export const LIMIT = process.env.REACT_APP_LIMIT || 1
export const ACCESS = {
    ALLOWED: 'allowed',
    BLOCKED: 'blocked',
    AUTHORIZED: 'Autorisé',
    NOT_AUTHORIZED: 'Bloqué',
}
export const ROLES = {
    ADMIN: 'ADMIN',
    SUPERVISOR: 'SUPERVISOR',
    CLIENT: 'CLIENT',
}
