import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import BaseService from 'services/BaseService'
import { popNotification } from 'utils/popNotification'
import { notificationConfig } from './constants'
import { LIMIT } from 'constants/app.constant'

const { creation, edit, deletion } = notificationConfig

// Redux Toolkit slice
const clientsSlice = createSlice({
    name: 'clients',
    initialState: {
        data: [],
        isLoading: false,
        error: null,
        created: null,
        isDeleted: null,
        currentPage: 1,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchClients.pending, (state) => {
                state.isLoading = true
                state.error = null
            })
            .addCase(fetchClients.fulfilled, (state, action) => {
                const currentPage = action?.meta?.arg?.page || 1
                state.currentPage = currentPage
                state.isLoading = false
                state.data = action.payload
            })
            .addCase(fetchClients.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
            })
            .addCase(createClient.pending, (state) => {
                state.isLoading = true
                state.error = null
            })
            .addCase(createClient.fulfilled, (state, action) => {
                state.isLoading = false
                state.created = true
            })
            .addCase(createClient.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
                state.created = false
            })
            .addCase(deleteClient.pending, (state) => {
                state.isLoading = true
                state.isDeleted = null
            })
            .addCase(deleteClient.fulfilled, (state) => {
                state.isLoading = false
                state.isDeleted = true
            })
            .addCase(deleteClient.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
                state.isDeleted = false
            })
    },
})

// Async thunk action to fetch data from the API
export const fetchClients = createAsyncThunk(
    'clients/fetch',
    async (payload) => {
        const page = payload?.page || 1
        const limit = payload?.limit || LIMIT
        try {
            const response = await BaseService.get(
                `/clients?page=${page}&limit=${limit}`
            )
            return response.data
        } catch (error) {
            throw new Error('Failed to fetch clients')
        }
    }
)

export const createClient = createAsyncThunk(
    'clients/post',
    async (payload) => {
        const { data, formik, navigate } = payload
        const { resetForm, setSubmitting } = formik
        try {
            const response = await BaseService.post('/clients', data)
            if (response.status === 200) {
                resetForm()
                setSubmitting(false)
                popNotification(creation?.success)
                // navigate('/clients')
                return true
            }
        } catch (err) {
            console.log({ err })
            const status = err.response.status
            setSubmitting(false)
            if (status === 409) {
                popNotification(creation?.conflict)
                return
            }
            popNotification(creation?.error)
        }
    }
)

export const editClient = createAsyncThunk('clients/edit', async (payload) => {
    const { data, formik, navigate } = payload
    const { resetForm, setSubmitting } = formik
    try {
        const response = await BaseService.put(`/clients/${data?.id}`, data)
        if (response.status === 200) {
            resetForm()
            setSubmitting(false)
            popNotification(edit?.success)
            // navigate('/clients')
            return true
        }
    } catch (err) {
        console.log({ err })
        const status = err.response.status
        setSubmitting(false)
        if (status === 409) {
            popNotification(creation?.conflict)
            return
        }
        popNotification(edit?.error)
    }
})

export const deleteClient = createAsyncThunk(
    'client/delete',
    async (payload) => {
        const { id, closeDialog } = payload
        if (!id) return
        try {
            const response = await BaseService.delete(`/clients/${id}`)
            if (response.status === 200 || response.status === 201) {
                popNotification(deletion?.success)
                closeDialog()
                return true
            }
        } catch (err) {
            console.log({ err })
            popNotification(deletion?.error)
            return
        }
    }
)

export const selectData = (state) => state.clients.data
export const selectIsLoading = (state) => state.clients.isLoading
export const selectError = (state) => state.clients.error
export const selectIsDeleted = (state) => state.clients.isDeleted
export const selectCurrentPage = (state) => state.clients.currentPage

export default clientsSlice.reducer
