const crypto = require('crypto-browserify');
window.Buffer = window.Buffer || require('buffer').Buffer;
// const { Buffer } = require('buffer');


const encryptionKey = process.env.REACT_APP_ENCRYPTION_KEY || ''
const IV_LENGTH = 16; 

function decrypt(text) {
  
  if (!text || typeof text !== 'string' || text.indexOf(':') === -1) {
    throw new Error('Invalid encrypted text format.');
  }
  let textParts = text.split(':');
  if (textParts[0].length !== IV_LENGTH * 2) { 
    throw new Error('Invalid initialization vector length.');
  }
  let iv = Buffer.from(textParts.shift(), 'hex');
    let encryptedText = Buffer.from(textParts.join(':'), 'hex');
    let decipher = crypto.createDecipheriv('aes-256-cbc', Buffer.from(encryptionKey), iv);

    let decrypted;
    try {
        decrypted = decipher.update(encryptedText);
        decrypted = Buffer.concat([decrypted, decipher.final()]);
    } catch (err) {
        throw new Error('Decryption failed. !');
    }
    return decrypted.toString();
}

export default decrypt;
