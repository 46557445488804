import { combineReducers } from 'redux'
import theme from './theme/themeSlice'
import auth from './auth'
import base from './base'
import locale from './locale/localeSlice'
import supervisors from './supervisors/supervisorsSlice'
import distributors from './distributors/distributorsSlice'
import selectedAction from './selectedAction/selectedActionSlice'
import clients from './clients/clientsSlice'
import cities from './cities/citiessSlice'
import missions from './missions/missionsSlice'

const rootReducer = (asyncReducers) => (state, action) => {
    const combinedReducer = combineReducers({
        theme,
        auth,
        base,
        locale,
        supervisors,
        distributors,
        selectedAction,
        clients,
        cities,
        missions,
        ...asyncReducers,
    })
    return combinedReducer(state, action)
}

export default rootReducer
