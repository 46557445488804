import { toast, Notification } from 'components/ui'

export const popNotification = ({ type, title, duration, message }) => {
    toast.push(
        <Notification title={title} type={type} duration={duration}>
            {message}
        </Notification>,
        {
            placement: 'top-center',
        }
    )
}
