export const SUCCESS_MESSAGE = 'Votre opération est effectuée avec succès'
export const ERROR_MESSAGE = 'Votre opération est échouée, veuillez réessayer'
export const CONFLICT_EMAIL_MESSAGE =
    "L'email que vous avez saisi est déjà existant"

export const CONFLICT_DEPLIANTS_QTE =
    'La nouvelle quantité des dépliants est inferieur à la somme des dépliants déclarée dans quatiers.'
export const TYPES = {
    SUCCESS: 'success',
    ERROR: 'danger',
}
